import api from '@/core/services/apisaas.service.js'
// api.init()
// api.setHeader()

const CrudModuleSaas = {

  async createDb(route, form = {}) {
    let data = {}
    let result = await Swal.fire({
      title: 'Buat Database ?',
      text: '',
      icon: 'question',
      showCancelButton: true
    }).then(async function (result) {
      if (result.value) {
        SwalLoading.fire();
        return await api.post(route, form)
          .then((response) => {
            console.log('response create di mdoule', response)
            data = response.data
            return true
          }).catch((error) => {
            return false
          })
      } else {
        return false
      }
    })
    return {
      status: result,
      data: data
    }
  },

  // async createDb(route) {
  //   let state = "", validationError = "", success = ""
  //   // SwalLoading
  //   // SwalLoading.fire()
  //   await api.post(route)
  //     .then((response) => {
  //       // //('response', response)
  //       state = 'success'
  //       success = response.data
  //     }).catch((error) => {
  //       //('error', error)
  //       if (error.response.status == 422) {
  //         state = 'error'
  //         validationError = error.response.data
  //       }
  //     }).finally(() => {
  //       // Close SwalLoading
  //       // SwalLoading.close()
  //     })
  //   return {
  //     state: state,
  //     error: validationError,
  //     success: success,
  //   }
  // },

  async submitWithoutAlert(data, route) {
    let state = "", validationError = "", success = ""
    // SwalLoading
    // SwalLoading.fire()
    await api.post(route, data)
      .then((response) => {
        // //('response', response)
        state = 'success'
        success = response.data
      }).catch((error) => {
        //('error', error)
        if (error.response.status == 422) {
          state = 'error'
          validationError = error.response.data
        }
      })
    return {
      state: state,
      error: validationError,
      success: success,
    }
  },

  async createMigration(route, data = {}) {
    let state = "", validationError = "", success = ""
    await api.post(route, data)
      .then((response) => {
        // //('response', response)
        state = 'success'
        success = response.data
      }).catch((error) => {
        //('error', error)
        if (error.response.status == 422) {
          state = 'error'
          validationError = error.response.data
        }
      }).finally(() => {
      })
    return {
      state: state,
      error: validationError,
      success: success,
    }
  },

  async migrateDb(route, data = {}) {
    let state = "", validationError = "", success = ""
    // SwalLoading
    // SwalLoading.fire()
    await api.post(route, data)
      .then((response) => {
        // //('response', response)
        state = 'success'
        success = response.data
      }).catch((error) => {
        //('error', error)
        if (error.response.status == 422) {
          state = 'error'
          validationError = error.response.data
        }
      }).finally(() => {
        // Close SwalLoading
        // SwalLoading.close()
      })
    return {
      state: state,
      error: validationError,
      success: success,
    }
  },

}

export default CrudModuleSaas